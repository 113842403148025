export const faqs = [
  {
    key: 1,
    question: "What do I need to rent a vehicle?",
    answer:
      "A valid driver's license, passport, and a valid means of payment. All of these items must be submitted on the day the vehicle will be picked up. If you wish to pay in cash it's also possible, you can Call us and we will arrange everything smoothly",
  },
  {
    key: 2,
    question: "Do I need insurance?",
    answer:
      "We offer a wide range of rental insurance products for people who want to be on the safe side.",
  },
  {
    key: 3,
    question: "Can anyone besides the reservation applicant drive the vehicle?",
    answer:
      "Yes; however, each person who intends to drive the vehicle must submit a valid driver’s license on the day the vehicle will be picked up. If it is not possible for all drivers to be present on the day of pick-up, a copy of their driver’s license (both sides) and passport must be prepared in advance and submitted.",
  },
  {
    key: 4,
    question: "Can I rent a vehicle without a reservation?",
    answer:
      "Yes, as long as a vehicle is available in the station; however, we recommend making a reservation because no vehicle may be available during busy periods.",
  },
  {
    key: 5,
    question: "For how many days can I reserve a rental vehicle?",
    answer:
      "We accept reservations for a rental period of as much as possible, ask and we will give you the answer.",
  },
  {
    key: 6,
    question: "Can I pay with payment wallet like Vodafone, Etisalat Cash & etc.?",
    answer:
      "Yes, you can.",
  },
];
