import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Popover, PopoverTrigger, PopoverContent } from "./ui/popover";
import { navItems } from "../data/navItems";
import { Button } from "./ui/button";
import { Icons } from "./Icons";
import { useState, useEffect } from "react";

function NavMobile() {
  const [open, setOpen] = useState(false);

  const MOBILE_BREAKPOINT = 768;
  const getIsMobile = () => window.innerWidth < MOBILE_BREAKPOINT;
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? getIsMobile() : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (open && isMobile) {
      document.body.classList.add("overflow-hidden");
    }

    return () => document.body.classList.remove("overflow-hidden");
  }, [open, isMobile]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button size="sm" variant="ghost">
          {open ? (
            <Icons.ChevronUp className="h-6 w-6 text-white" />
          ) : (
            <Icons.Menu className="h-6 w-6 text-white" />
          )}
        </Button>
      </PopoverTrigger>
      {isMobile && (
        <PopoverContent
          sideOffset={30}
          className="w-screen h-[calc(100vh_-_96px)] p-6 rounded-none border-none"
        >
          <nav className="grid grid-cols-1">
            <ul>
              {navItems.map((navItem) => (
                <li
                  key={navItem.title}
                  className="text-lg text-neutral-300 hover:text-white transition-colors font-semibold py-[25px] text-center "
                >
                  {navItem.isHashLink ? (
                    <HashLink smooth to={navItem.href} className="w-full block">
                      {navItem.title}
                    </HashLink>
                  ) : (
                    <Link
                      to={navItem.href}
                      className="w-full block"
                      onClick={() => setOpen(false)}
                    >
                      {navItem.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            {/* <div className="mt-6 space-y-5">
              <button className="font-semibold w-full flex items-center justify-center bg-transparent px-2 py-2 rounded-md border border-neutral-300 hover:border-white transition-all duration-150">
                Login
              </button>
              <button className="font-semibold w-full flex items-center justify-center bg-white text-black px-2 py-2 rounded-md border hover:bg-transparent hover:text-white hover:border-white transition-all duration-150">
                Download the app
              </button>
            </div> */}
          </nav>
        </PopoverContent>
      )}
    </Popover>
  );
}

export function SiteHeader() {
  return (
    <div>
      <div className="flex flex-row px-6 p-.5 justify-around items-end text-center font-semi-bold text-white text-xs font-black bg-red-800 w-full">
        <div className="flex flex-row gap-2 justify-center items-center">
            <small className=" text-white">WA|Call</small>
          <div className="bg-white rounded-lg p-1">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
          </div>
          <p className="animate-pulse text-white font-extrabold tracking-widest">+2 0100 70 170 50</p>
        </div>
        {/* <div className="flex flex-row gap-2 justify-center items-center ">
          <div className="bg-white rounded-lg p-1">
            <svg xmlns="http://www.w3.org/2000/svg" height="1.25em" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
          </div>
          <p className="text-white font-normal ">mahmoud.selmy78@gmail.com</p>
        </div> */}
      </div>
    <header className="h-24 w-full flex flex-row items-center justify-between px-6 2xl:px-0 max-w-screen-xl mx-auto">
      <div className="flex flex-row items-center justify-start">
        <Link
          to="/"
          className="font-semibold text-[26px] tracking-wide leading-none bg-white text-black p-1.5 rounded-lg px-2 animate-bounce"
        >
            <span id="logo" className="text-red-700 font-extrabold">Rai </span> 
            <small className="font-semibold text-sm"> Cars</small>
        </Link>
        <nav className="hidden md:block ml-20">
          <ul className="flex flex-row gap-x-16">
            {navItems.map((navItem) => (
              <li
                key={navItem.title}
                className="text-neutral-300 hover:text-white transition-colors font-bold"
              >
                {navItem.isHashLink ? (
                  <HashLink smooth to={navItem.href}>
                    {navItem.title}
                  </HashLink>
                ) : (
                  <Link to={navItem.href}>{navItem.title}</Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="md:hidden">
        <NavMobile />
      </div>
    </header>
    </div>
  );
}
