export const vehicles = [
  {
    id: "VEH001",
    slug: "mercedes-benz",
    brand: { id: "b-mercedes", name: "Mercedes Benz" },
    model: "E-200",
    year: 2018,
    description:
      "A car for your luxury, while you a business, someone who wants extra comfort or you have a particular need for a luxury car, this one is the best choice.",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
      // perDay: { retailPrice: 99, discountPrice: 79 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 2000 cc turbo",
      "Autopilot",
      "DriveTrain 9-speed auto, rear-wheel drive",
      '15" Center Touchscreen',
      '20" wheels',
      "0-100km/h 7.70sec",
      "Dual Motor All-Wheel Drive",
      "Air Conditioning",
      "Weight 1605kg"
    ],
    images: [
      {
        type: "cover",
        alt: "Mercedes Benz E-200 (2018)",
        urlPath: "./assets/images/vehicles/e200-white.jpg",
      },
      {
        type: "display",
        alt: "Mercedes Benz E-200 (2018)",
        urlPath: "./assets/images/vehicles/e200-white.jpg",
      },
    ],
  },
  {
    id: "VEH002",
    slug: "kia",
    brand: { id: "b-kia", name: "Kia" },
    model: "Grand Cerato",
    year: 2022,
    description:
      "A modern-style car to conquer the city or ride it to explore the neighboring cities, nice experience with a sportive feeling.",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
      // perDay: { retailPrice: 99, discountPrice: 79 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "6-speed auto",
      " Touchscreen",
      '16" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Kia Grand Cerato (2022)",
        urlPath: "./assets/images/vehicles/grand-cerato.jpg",
      },
      {
        type: "display",
        alt: "Kia Grand Cerato (2022)",
        urlPath: "./assets/images/vehicles/grand-cerato.jpg",
      },
    ],
  },
  {
    id: "VEH003",
    slug: "hyundai-elentra-facelift",
    brand: { id: "b-hyundai", name: "Hyundai" },
    model: "Elentra FaceLift",
    year: 2020,
    description:
      "Try the most famous brand in Egypt, and know why we love Hyundai cars, a light weight car that can be used for whatever reason.",
    color: { id: "c-gray", name: "gray" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
      // perDay: { retailPrice: 99, discountPrice: 79 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "6-speed auto",
      " Touchscreen",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Elentra FaceLift 2020",
        urlPath: "./assets/images/vehicles/facelift.jpg",
      },
      {
        type: "display",
        alt: "Elentra FaceLift 2020",
        urlPath: "./assets/images/vehicles/facelift.jpg",
      },
    ],
  },
  {
    id: "VEH004",
    slug: "toyota-corola-2016",
    brand: { id: "b-toyouta", name: "Toyota" },
    model: "Corola",
    year: 2016,
    description:
      "Nice stable Japanese vehicle that works continuously, is error-prone, comfortable, and economical in terms of everything.",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "5-speed auto",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Toyota Corola 2016",
        urlPath: "./assets/images/vehicles/corola.jpg",
      },
      {
        type: "display",
        alt: "Toyota Corola 2016",
        urlPath: "./assets/images/vehicles/corola.jpg",
      },
    ],
  },
  {
    id: "VEH005",
    slug: "seat-leon-2016",
    brand: { id: "b-seat", name: "Seat" },
    model: "Leon",
    year: 2016,
    description:
      "Nice stable vehicle that works good,comfortable, and economical in terms of everything, it's great for those who do not travel long",
    color: { id: "c-Blue", name: "Blue" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "5-speed auto",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Toyota Corola 2016",
        urlPath: "./assets/images/vehicles/ciat.jpg",
      },
      {
        type: "display",
        alt: "Toyota Corola 2016",
        urlPath: "./assets/images/vehicles/ciat.jpg",
      },
    ],
  },
  {
    id: "VEH006",
    slug: "kia-cerato-2016",
    brand: { id: "b-kia", name: "Kia" },
    model: "Cerato",
    year: 2016,
    description:
      "Nice stable vehicle that works good,comfortable, and economical in terms of everything, it's great for those who do not travel long",
    color: { id: "c-red", name: "red" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "5-speed auto",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Kia Cerato 2016",
        urlPath: "./assets/images/vehicles/KIA-RED.jpg",
      },
      {
        type: "display",
        alt: "Kia Cerato 2016",
        urlPath: "./assets/images/vehicles/KIA-RED.jpg",
      },
    ],
  },
  {
    id: "VEH007",
    slug: "hyundai-2",
    brand: { id: "b-hyundai", name: "Hyundai" },
    model: "Elentra",
    year: 2016,
    description:
      "Nice stable vehicle that works good,comfortable, and economical in terms of everything, it's great for those who do not travel long",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "5-speed auto",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Hyundai Elentra 2016",
        urlPath: "./assets/images/vehicles/elentra2.jpg",
      },
      {
        type: "display",
        alt: "Hyundai Elentra 2016",
        urlPath: "./assets/images/vehicles/elentra2.jpg",
      },
    ],
  },
  {
    id: "VEH008",
    slug: "hyundai-3",
    brand: { id: "b-hyundai", name: "Hyundai" },
    model: "Tucson",
    year: 2019,
    description:
      "Nice stable vehicle that works good,comfortable, and economical in terms of everything, it's great for those who do not travel long",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1600 cc",
      "5-speed auto",
      '15" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Hyundai Elentra 2016",
        urlPath: "./assets/images/vehicles/tucson.jpg",
      },
      {
        type: "display",
        alt: "Hyundai Elentra 2016",
        urlPath: "./assets/images/vehicles/tucson.jpg",
      },
    ],
  },
  {
    id: "VEH009",
    slug: "mercedes-benz-2",
    brand: { id: "b-mercedes", name: "Mercedes Benz" },
    model: "E-200",
    year: 2020,
    description:
      "A car for your luxury, while you a business, someone who wants extra comfort or you have a particular need for a luxury car, this one is the best choice.",
    color: { id: "c-black", name: "black" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 2000 cc turbo",
      "Autopilot",
      "DriveTrain 9-speed auto, rear-wheel drive",
      '15" Center Touchscreen',
      '20" wheels',
      "0-100km/h 7.70sec",
      "Dual Motor All-Wheel Drive",
      "Air Conditioning",
      "Weight 1605kg"
    ],
    images: [
      {
        type: "cover",
        alt: "Mercedes Benz E-200 (2020)",
        urlPath: "./assets/images/vehicles/e200-black.jpg",
      },
      {
        type: "display",
        alt: "Mercedes Benz E-200 (2020)",
        urlPath: "./assets/images/vehicles/e200-black.jpg",
      },
    ],
  },
  {
    id: "VEH010",
    slug: "volkas-passat",
    brand: { id: "b-volks-wagen", name: "Volks Wagen" },
    model: "Passat",
    year: 2023,
    description:
      "Want to experience 2023 ride, here we have a fresh 2023 bassat cars that fits your elegance, it fits almost all your needs.",
    color: { id: "c-black", name: "black" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1300 cc turbo",
      "Autopilot",
      "DriveTrain 6-speed auto, rear-wheel drive",
      '15" Center Touchscreen',
      '18" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Passat 2023 (2023)",
        urlPath: "./assets/images/vehicles/passat-black.jpg",
      },
      {
        type: "display",
        alt: "Passat 2023 (2023)",
        urlPath: "./assets/images/vehicles/passat-black.jpg",
      },
    ],
  },
  {
    id: "VEH011",
    slug: "volkas-passat-2",
    brand: { id: "b-volks-wagen", name: "Volks Wagen" },
    model: "Passat",
    year: 2023,
    description:
      "Want to experience 2023 ride, here we have a fresh 2023 bassat cars that fits your elegance, it fits almost all your needs.",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 1300 cc turbo",
      "Autopilot",
      "DriveTrain 6-speed auto, rear-wheel drive",
      '15" Center Touchscreen',
      '18" wheels',
      "Air Conditioning",
    ],
    images: [
      {
        type: "cover",
        alt: "Passat 2023 (2023)",
        urlPath: "./assets/images/vehicles/passat-white.jpg",
      },
      {
        type: "display",
        alt: "Passat 2023 (2023)",
        urlPath: "./assets/images/vehicles/passat-white.jpg",
      },
    ],
  },
  {
    id: "VEH012",
    slug: "jeep",
    brand: { id: "jeep", name: "Jeep Wrangler" },
    model: "Jeep Rangler",
    year: 2015,
    description:
      "For those who desire for muscles and power with no limits",
    color: { id: "c-white", name: "white" },
    featured: true,
    price: {
      perDay: { retailPrice: 0 },
    },
    details: {
      transmission: "automatic",
      // capacity: "5 adults",
    },
    features: [
      "Engine 2600 cc",
      "Automatic",
      "6-spd man w/OD",
      '5 Passengers',
      '3.6L V-6',
      "Air Con",
    ],
    images: [
      {
        type: "cover",
        alt: "Jeep",
        urlPath: "./assets/images/vehicles/jeep.jpg",
      },
      {
        type: "display",
        alt: "Jeep",
        urlPath: "./assets/images/vehicles/jeep.jpg",
      },
    ],
  },
];
